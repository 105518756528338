<template>
    <section
        v-if="pricing.length"
        id="pricing-plan"
    >
        <!-- title text and switch button -->
        <div class="text-center">
            <h1 class="mt-1">
               Monthly Pricing Plans
            </h1>
<!--            <p class="mb-2 pb-75">-->
<!--                All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.-->
<!--            </p>-->
        </div>
        <!-- pricing plan cards -->
        <b-row class="pricing-card">
            <b-col
                offset-sm-2
                sm="10"
                md="12"
                offset-lg="2"
                lg="10"
                class="mx-auto"
            >
                <b-row>
                    <b-col md="4" v-for="pValue in pricing">
                        <b-card
                            align="center"
                        >
                            <h3>{{ pValue.title }}</h3>
                            <b-card-text>
                                {{ pValue.subtitle }}
                            </b-card-text>

<!--                            <div
                                class="pricing-badge text-right"
                            >
                                <b-badge
                                    variant="light-primary"
                                    pill
                                >
                                    Popular
                                </b-badge>
                            </div>-->

                            <!-- monthly plan -->
                            <div class="annual-plan">
                                <div class="plan-price mt-2">
                                    <sup class="font-medium-1 font-weight-bold text-primary">{{ pValue.currency }}</sup>
                                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ pValue.price }}</span>
                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                </div>
                            </div>
                            <!--/ monthly plan -->

                            <!-- plan benefit -->
                           <b-list-group class="list-group-circle text-left">
                                <b-list-group-item
                                    v-for="(data,index) in JSON.parse(pValue.description)"
                                    :key="index"
                                >
                                    {{ data }}
                                </b-list-group-item>
                            </b-list-group>
                            <!--/ plan benefit -->

                            <!-- buttons -->
                            <b-button
                                v-if="current_package && pValue.id == current_package.service_package_id"
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                block
                                class="mt-2"
                                variant="outline-success"
                                @click="activeServicePlane(pValue.id)"
                            >
                                Your Current Plan
                             </b-button>
                            <b-button
                                v-else
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                block
                                class="mt-2"
                                variant="outline-primary"
                                @click="activeServicePlane(pValue.id)"
                            >
                                Upgrade
                                <!--Your current plan-->
                            </b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!--/ pricing plan cards -->
    </section>
</template>

<script>
import {
    BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { SERVICES_PACKAGE_LIST } from "@core/services/api";
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormCheckbox,
        BButton,
        BCardText,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol,
        BCard,
        BBadge,
        BImg,
        AppCollapseItem,
        AppCollapse,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            pricing: {},
            current_package:'',
        }
    },
    created() {
        this.servicesPackageList();
    },
    methods: {
        servicesPackageList() {
            this.$http
                .get(process.env.VUE_APP_BASEURL + SERVICES_PACKAGE_LIST)
                .then((res) => {
                    let data = res.data.data;
                    this.pricing = data.service_package;
                    this.current_package = data.current_package;
                   // console.log(this.current_package);
                });
        },
        activeServicePlane(servicePlanId){
            useJwt.ispServicePackagePay({
                service_package_id:servicePlanId
            }).then(response => {
                console.log(response.data.code)
                if (response.data.code == 200 && response.data.status == "success") {
                    window.location.href = response.data.data
                }else if(response.data.code == 422 && response.data.status_message == "warning"){
                    this.toastMessage(response.data.status_message,'Pricing Plan', response);
                }else {
                    this.toastMessage(response.data.status_message,'Pricing Plan', response);
                }
            }).catch(error => {
                console.log(error)
                this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
    },
}
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
